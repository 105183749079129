import { useFeatureFlagsQuery } from "../../app/services/auth";
import useRole from "./useRole";

const permissions: IPermissions = {
  createDataInPeaka: false,
  queries: false,
  tables: true
};

const defaultPermissions = {
  createDataInPeaka: true,
  queries: true,
  tables: true
};

export interface IPermissions {
  createDataInPeaka?: boolean;
  queries?: boolean;
  tables: boolean;
}

const useHasPermission = () => {
  const role = useRole();
  const { data: permissions } = useFeatureFlagsQuery(undefined, {
    skip: role !== "PARTNER_GUEST"
  });

  if (role === "PARTNER_GUEST" && permissions) {
    return { ...defaultPermissions, ...permissions };
  }
  return defaultPermissions;
};

export default useHasPermission;
