import type { FormEvent } from "react";
import { useState } from "react";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  useUpdateApiKeyMutation,
  type ApiKeyResultType,
  useCreateApiKeyMutation
} from "../../app/services/apiKey";
import { ApiKeyResultContent, ApiKeyResultFooter } from "./ApiKeyResult";
import {
  PeakaButton,
  PeakaCalendar,
  PeakaDropdown,
  PeakaInputText
} from "@code2io/fe-studio-component-library";
import PeakaDialog from "../../app/utils/peakaDialog";
import type { Nullable } from "vitest";
import moment from "moment";
import { sendPosthogEvent } from "../../app/utils/PosthogEventUtils";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../auth/authSlice";

export interface ApiKeyFormProps {
  appId: string;
  apiKey?: ApiKeyResultType;
  className?: string;
  isGenerate: boolean;
  newApiKeyNumber?: string;
  onCancel: () => void;
  onHide: () => void;
  visible: boolean;
}

const ApiKeyForm = ({
  appId,
  apiKey,
  className,
  onCancel,
  isGenerate,
  newApiKeyNumber,
  visible,
  onHide
}: ApiKeyFormProps) => {
  const [blocked, setBlocked] = useState(false);

  const [apiKeyName, setApiKeyName] = useState(
    isGenerate ? newApiKeyNumber : apiKey!.name
  );
  const [updateApiKey] = useUpdateApiKeyMutation();
  const [createApiKey] = useCreateApiKeyMutation();
  const [apiKeyValue, setApiKeyValue] = useState("");
  const [apiKeyExpiration, setApiKeyExpiration] = useState("");
  const [isApiKeyGenerated, setIsApiKeyGenerated] = useState(false);
  const expirationOptions = [
    { name: "7 Days", value: "7" },
    { name: "30 Days", value: "30" },
    { name: "60 Days", value: "60" },
    { name: "Custom", value: "custom" },
    { name: "No Expiration", value: "no-expire" }
  ];
  const [expiration, setExpiration] = useState<string>("30");
  const [expirationDate, setExpirationDate] = useState<Nullable<Date>>();
  const user = useAppSelector(selectUser);

  return (
    <>
      <BlockUI
        blocked={blocked}
        containerClassName="flex flex-column flex-1"
        template={<ProgressSpinner />}
      />

      <PeakaDialog
        size="sm"
        visible={visible}
        onHide={() => {
          onHide();
          setTimeout(() => {
            setIsApiKeyGenerated(false);
          }, 1000);
        }}
        header={<div>{isGenerate ? "Generate API Key" : "Rename API Key"}</div>}
        content={
          isApiKeyGenerated ? (
            <ApiKeyResultContent
              apiKey={apiKeyValue}
              expiresAt={apiKeyExpiration}
            />
          ) : (
            <form id="generate-api-key-form" noValidate onSubmit={handleSubmit}>
              <div className="field">
                <PeakaInputText
                  label="API Key Name"
                  autoComplete="off"
                  autoFocus
                  size="md"
                  wrapperClassName="w-full"
                  id="apiKeyName"
                  onChange={(e) => setApiKeyName(e.target.value)}
                  value={apiKeyName}
                />
              </div>
              {isGenerate && (
                <>
                  <div className="field grid">
                    <PeakaDropdown
                      label="Expiration"
                      options={expirationOptions}
                      optionLabel="name"
                      autoFocus
                      size="md"
                      wrapperClassName="w-full col-12"
                      id="expiration"
                      onChange={(e) => setExpiration(e.target.value)}
                      value={expiration}
                    />
                  </div>
                  {expiration === "custom" && (
                    <div className="field grid">
                      <div className="col-12 w-full">
                        <PeakaCalendar
                          wrapperClassName="w-full"
                          id="date-default-value"
                          label="Expiration Date"
                          value={expirationDate}
                          onChange={(e) => setExpirationDate(e.value)}
                          dateFormat="mm/dd/yy"
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </form>
          )
        }
        footer={
          isApiKeyGenerated ? (
            <ApiKeyResultFooter
              onDone={() => {
                onHide();
                setTimeout(() => {
                  setIsApiKeyGenerated(false);
                }, 1000);
              }}
            />
          ) : (
            <div className="flex align-items-center justify-content-end gap-2">
              <PeakaButton
                severity="secondary"
                label="Cancel"
                onClick={() => onCancel()}
                type="button"
              />
              <PeakaButton
                form="generate-api-key-form"
                label={isGenerate ? "Generate" : "Update"}
                type="submit"
                disabled={blocked}
                loading={blocked}
              />
            </div>
          )
        }
      />
    </>
  );

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    try {
      setBlocked(true);
      if (isGenerate) {
        const result = await createApiKey({
          scopes: [],
          name: apiKeyName!,
          authorizedAppId: appId,
          partner: false,
          expiration:
            expiration && !Number.isNaN(parseInt(expiration))
              ? moment(new Date()).add(parseInt(expiration), "days").toDate()
              : expiration === "custom"
              ? expirationDate
              : null
        }).unwrap();
        setApiKeyValue(result.apiKey);
        setApiKeyExpiration(result.expiresAt);
        setIsApiKeyGenerated(true);

        sendPosthogEvent("studio_create_project_key", {
          method: "studio_create_project_key",
          email: user?.email
        });
      } else {
        await updateApiKey({
          id: apiKey!.id,
          body: {
            scopes: [],
            name: apiKeyName!,
            authorizedAppId: appId,
            partner: false
          }
        });
        onCancel();
      }
      setBlocked(false);
    } catch (e) {
      // TODO handle error
    } finally {
      setBlocked(false);
    }
  }
};

export default ApiKeyForm;
