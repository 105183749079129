import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import type { User } from "../../features/auth/authSlice";
import {
  setRefreshCode,
  setToken,
  setUser
} from "../../features/auth/authSlice";
import jwt_decode from "jwt-decode";
import { getDynamicConfigValue } from "../utils/dynamicConfig";
import { EnvironmentConstants } from "../../environmentConstants";
import { refreshLock } from "../refreshLock";
import mixpanel from "mixpanel-browser";
import type { RootState } from "../store";

const pendingRequests: ((token: string) => Promise<void>)[] = []; // Queue for pending requests
let isRefreshing = false; // Flag to track if a token refresh is in progress

const baseUrl = getDynamicConfigValue(EnvironmentConstants.STUDIO_API_URL);

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    } else {
      headers.set("Authorization", `Bearer null`);
    }
    headers.set("X-Requested-With", "XMLHttpRequest");
    return headers;
  }
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await refreshLock.waitForUnlock();
  let result = await baseQuery(
    typeof args === "string"
      ? { url: args, credentials: "include" }
      : { ...args, credentials: "include" },
    api,
    extraOptions
  );
  if (
    result.error?.status === 401 &&
    (result.error.data as { reasonCode: number; reason: string } | undefined)
      ?.reasonCode === 2
  ) {
    window.location.href = "/2fa-validation";
  } else if (result.error?.status === 401) {
    if (!isRefreshing) {
      isRefreshing = true; // Mark token refresh as in progress
      const release = await refreshLock.acquire();
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const refreshResult: ReturnType<typeof baseQuery> =
          await navigator.locks.request("refreshToken", async () => {
            const baseQueryArgs: FetchArgs = {
              credentials: "include",
              url: `jwt/refresh`
            };
            if (
              window.origin.includes(
                getDynamicConfigValue(EnvironmentConstants.PARTNER_DOMAIN)
              )
            ) {
              const urlParams = new URLSearchParams(window.location.search);
              const refreshCode =
                (api.getState() as RootState).auth.refreshCode ??
                urlParams.get("refreshCode");

              baseQueryArgs.headers = {
                "X-Peaka-Refresh-Code": refreshCode ?? undefined
              };
            }

            return await baseQuery(baseQueryArgs, api, extraOptions);
          });
        if ("data" in refreshResult) {
          const accessToken = (
            refreshResult as { data: { access_token: string } }
          ).data.access_token;
          const refreshCode = (
            refreshResult as { data: { refresh_code: string } }
          ).data.refresh_code;
          api.dispatch(setToken(accessToken));
          const decodedToken: User = jwt_decode(accessToken);
          if (refreshCode) api.dispatch(setRefreshCode(refreshCode));

          api.dispatch(setUser(decodedToken));
          // Retry all pending requests with the new token
          while (pendingRequests.length > 0) {
            const retryRequest = pendingRequests.shift();
            if (retryRequest) {
              await retryRequest(accessToken);
            }
          }
          result = await baseQuery(
            typeof args === "string"
              ? { url: args, credentials: "include" }
              : { ...args, credentials: "include" },
            api,

            extraOptions
          );
        } else {
          // log out
          if ((api.getState() as RootState).auth.user) {
            try {
              mixpanel.reset();
            } catch (e) {
              // console.log(e)
            }
          }
          window.location.href =
            "/login?redirectPath=" + window.location.pathname;
        }
      } finally {
        isRefreshing = false; // Mark token refresh as complete
        release();
      }
    } else {
      await new Promise<void>((resolve) => {
        pendingRequests.push(async (token) => {
          result = await baseQuery(
            typeof args === "string"
              ? { url: args, credentials: "include" }
              : { ...args, credentials: "include" },
            api,
            extraOptions
          );
          resolve();
        });
      });
      /*
      refreshLock.waitForUnlock().then(async () => {
        console.log("token", (api.getState() as RootState).auth.token);
        result = await baseQuery(
          typeof args === "string"
            ? { url: args, credentials: "include" }
            : { ...args, credentials: "include" },
          api,
          extraOptions
        );
      }).catch((e) => {
        console.log(e)
      });
      */
    }
  }
  return result;
};

export default customFetchBase;
