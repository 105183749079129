import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./app/store";
import "./index.css";
import "./primeFlexExtensions.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { getDynamicConfigValue } from "./app/utils/dynamicConfig";
import { EnvironmentConstants } from "./environmentConstants";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import FrigadeProvider from "./app/components/FrigadeOnboarding/components/FrigadeProvider";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";

window.addEventListener("error", (error) => {
  if (
    error.message.includes(
      "Cannot submit op. Document has not been created. queries."
    )
  ) {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
    return false;
  }
  return true;
});

if (getDynamicConfigValue(EnvironmentConstants.ENVIRONMENT) === "production") {
  console.log = () => {
    /* Do nothing */
  };
  console.warn = () => {
    /* Do nothing */
  };
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

if (getDynamicConfigValue(EnvironmentConstants.GA_ENABLED) == "true") {
  ReactGA.initialize(getDynamicConfigValue(EnvironmentConstants.GA_API_KEY));
  const tagManagerArgs = {
    gtmId: getDynamicConfigValue(EnvironmentConstants.GTM_API_KEY)
  };

  TagManager.initialize(tagManagerArgs);
}

const isOnPremise =
  getDynamicConfigValue(EnvironmentConstants.ONPREMISE) === "false";

const isPostHogEnabled =
  getDynamicConfigValue(EnvironmentConstants.POSTHOG_ENABLED) === "true";

const params = new URLSearchParams(window.location.search);
const distinct_id = params.get("distinct_id");
const session_id = params.get("session_id");

if (isPostHogEnabled) {
  posthog.init(getDynamicConfigValue(EnvironmentConstants.POSTHOG_KEY), {
    api_host: getDynamicConfigValue(EnvironmentConstants.POSTHOG_HOST),
    bootstrap: {
      sessionID: session_id ?? undefined,
      distinctID: distinct_id ?? undefined
    }
  });
}

const AppWrapper = ({ children }: { children: React.ReactNode }) =>
  isOnPremise ? <FrigadeProvider>{children}</FrigadeProvider> : <>{children}</>;

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        {isPostHogEnabled ? (
          <PostHogProvider client={posthog}>
            <AppWrapper>
              <App />
            </AppWrapper>
          </PostHogProvider>
        ) : (
          <AppWrapper>
            <App />
          </AppWrapper>
        )}
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
