import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../app/store";

const initialState = {
  theme: localStorage.getItem("peaka-theme") ?? "dark"
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    setTheme: (
      state,
      action: PayloadAction<{ theme: string; internal?: boolean }>
    ) => {
      if (action.payload.internal) {
        localStorage.setItem("peaka-theme", action.payload.theme);
      }
      state.theme = action.payload.theme;
    }
  }
});

export const { setTheme } = appSettingsSlice.actions;

export const selectTheme = (state: RootState) => state.appSettings.theme;

export default appSettingsSlice.reducer;
