import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import C2Modal from "../components/C2Modal/C2Modal";
import { useCallback, useEffect, useState } from "react";
import { sendGAEvent } from "../utils/GAEventUtils";
import { AppConstants } from "../../environmentConstants";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  selectUser,
  setRefreshCode,
  setToken,
  setUser
} from "../../features/auth/authSlice";
import { useGetRefreshTokenQuery } from "../services/auth";
import jwt_decode from "jwt-decode";
import {
  identifyMXPanel,
  sendMixpanelEvent
} from "../utils/MixpanelEventUtils";

const ParentRoute = () => {
  const user = useAppSelector(selectUser);
  const [ref, setRef] = useState<string | null>();
  const socialAuth = sessionStorage.getItem(
    AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: refreshData, isLoading } = useGetRefreshTokenQuery();
  const checkSocialSignup = useCallback(() => {
    if (!ref) {
      setRef(searchParams.get("ref"));
    }
    if (!user) return;
    if (
      socialAuth === AppConstants.GOOGLE_LOGIN &&
      ref === AppConstants.GOOGLE_LOGIN
    ) {
      sendGAEvent("studio_login", {
        method: "Google",
        email: user.email
      });
      sendMixpanelEvent("studio_login", {
        method: "Google",
        email: user.email
      });
      identifyMXPanel(user);
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (
      socialAuth === AppConstants.GOOGLE_SIGNUP &&
      ref === AppConstants.GOOGLE_SIGNUP
    ) {
      sendGAEvent("studio_signup", {
        method: "Google",
        email: user.email
      });
      sendMixpanelEvent("studio_signup", {
        method: "Google",
        email: user.email
      });
      identifyMXPanel(user);
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (socialAuth) {
      sessionStorage.removeItem(AppConstants.SESSION_STORAGE_KEYS.SOCIAL_AUTH);
    } else if (ref === AppConstants.PASSWORD_SIGNUP) {
      identifyMXPanel(user);
      setSearchParams();
    } else if (ref === AppConstants.PASSWORD_LOGIN) {
      identifyMXPanel(user);
      setSearchParams();
    }
    if (user.twoFactorAuthEnabled && !user.twoFactorAuthVerified) {
      navigate("/2fa-validation");
    }
  }, [navigate, ref, searchParams, socialAuth, user]);

  useEffect(() => {
    checkSocialSignup();
  }, [checkSocialSignup]);

  useEffect(() => {
    if (refreshData) {
      dispatch(setToken(refreshData.access_token));
      dispatch(setUser(jwt_decode(refreshData.access_token)));
      dispatch(setRefreshCode(refreshData.refresh_code));
    }
  }, [dispatch, refreshData]);

  return (
    refreshData &&
    !isLoading && (
      <>
        <Outlet />
        <C2Modal />
      </>
    )
  );
};

export default ParentRoute;
