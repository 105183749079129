import { forwardRef, type CSSProperties, type ReactNode } from "react";
import type { DialogProps } from "primereact/dialog";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-regular-svg-icons";

export interface PeakaDialogShowOptions extends Partial<DialogProps> {
  autoFocus?: string;
  content?: ReactNode;
  className?: string;
  rootClassName?: string;
  headerStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  onHide: () => void;
  footerClassName?: string;
  headerClassName?: string;
}

const PeakaDialog = forwardRef<Dialog, PeakaDialogShowOptions>(
  function PeakaDialog(
    {
      autoFocus,
      content,
      className,
      size,
      onHide,
      contentStyle,
      headerStyle,
      header,
      headerClassName,
      footer,
      footerClassName,
      rootClassName,
      maskClassName,
      ...dialogProps
    },
    ref
  ) {
    return (
      <Dialog
        ref={ref}
        {...dialogProps}
        pt={{
          ...dialogProps.pt,
          mask: {
            className: classNames(
              "peaka-dialog p-component-overlay p-component-overlay-enter p-dialog-mask",
              maskClassName
            )
          },
          root: {
            className: classNames(
              size ? `peaka-dialog-${size}` : "peaka-dialog-md",
              rootClassName
            )
          },
          header: {
            className: classNames(headerClassName, "peaka-dialog-header", {
              hidden: header === null
            }),
            style: { ...headerStyle }
          },
          content: {
            className: classNames(
              dialogProps.contentClassName,
              "peaka-dialog-content",
              {
                "pk-rounded-top-xl pk-border-secondary border-top-1":
                  (header === null || !header) && !dialogProps.showHeader,
                "pk-rounded-bottom-xl pk-border-secondary border-bottom-1":
                  footer === null || !footer
              }
            ),
            style: { ...contentStyle }
          },
          footer: {
            className: classNames(footerClassName, "peaka-dialog-footer")
          },
          closeButton: {
            className: classNames(className, "peaka-dialog-close-button")
          }
        }}
        closeIcon={<FontAwesomeIcon icon={faClose} />}
        unstyled={true}
        onHide={onHide}
        header={header}
        footer={footer}
      >
        {content}
      </Dialog>
    );
  }
);

export default PeakaDialog;
