import { useAppSelector } from "../../app/hooks";
import { selectToken, selectUser, type User } from "./authSlice";
import type { IRole } from "./useAuth";
import jwt_decode from "jwt-decode";

const useRole = () => {
  const token = useAppSelector(selectToken);
  const user: User | null =
    useAppSelector(selectUser) ?? token !== null ? jwt_decode(token!) : null;

  const role: IRole = user?.developerFlag
    ? "DEVELOPER"
    : user?.partnerGuestUser
    ? "PARTNER_GUEST"
    : "USER";

  if (user) {
    return role;
  } else {
    return "";
  }
};

export default useRole;
