import posthog, { type EventName, type Properties } from "posthog-js";
import { EnvironmentConstants } from "../../environmentConstants";
import { getDynamicConfigValue } from "./dynamicConfig";

export const sendPosthogEvent = (
  eventName: EventName,
  properties: Properties
) => {
  if (getDynamicConfigValue(EnvironmentConstants.POSTHOG_ENABLED) == "true") {
    posthog.capture(eventName, properties);
  }
};
