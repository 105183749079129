import type { Nullable } from "primereact/ts-helpers";
import type { RequestDeveloperAccessFormData } from "../../features/advancedSettings/components/AdvancedSettingsRequestForm";
import { api } from "./api";

export interface ApiKeyResultType {
  id: string;
  name: string;
  keyPrefix: string;
  active: boolean;
  createdAt: string;
  scopes: string[];
  userId: string;
  authorizedAppId: string;
  partner: boolean;
  expiresAt: null | string;
  domainName?: string;
}

export interface CreateOrUpdateApiKeyQueryArg {
  scopes?: string[];
  name: string;
  authorizedAppId?: string;
  partner: boolean;
  catalogId?: string;
  expiration?: string | Nullable<Date>;
}

export interface UpdateApiKeyQueryArg {
  id: string;
  body: CreateOrUpdateApiKeyQueryArg;
}

export interface CreateApiKeyResultType {
  apiKey: string;
  id: string;
  expiresAt: string;
}

export interface requestDeveloperAccessAndPartnerAPIKeyQueryArg {
  companyName: string;
  username: string;
  purposeOfDeveloperAccess: string;
  email: string;
}

export type requestDeveloperAccessStatus =
  | "APPROVED"
  | "PENDING"
  | "REJECTED"
  | null;

export interface requestDeveloperAccessAndPartnerAPIKeyQueryResult {
  companyName: string;
  createdAt: string;
  id: string;
  purpose: string;
  status: requestDeveloperAccessStatus;
  updatedAt: string;
  userEmail: string;
  userName: string;
}

export interface requestDeveloperAccessAndPartnerAPIKeyQueryStatusResult {
  companyName: string;
  createdAt: string;
  id: string;
  purpose: string | null;
  status: requestDeveloperAccessStatus;
  updatedAt: string | null;
  userEmail: string;
  userName: string;
}

export const apiKeyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getApiKeys: builder.query<ApiKeyResultType[], { appId?: string }>({
      query: ({ appId }) => ({
        url: `/integration/apikey${appId ? "?appId=" + appId : ""}`,
        method: "GET"
      }),
      providesTags: ["ApiKeys"]
    }),
    createApiKey: builder.mutation<
      CreateApiKeyResultType,
      CreateOrUpdateApiKeyQueryArg
    >({
      query: (arg) => ({
        url: `/integration/apikey`,
        method: "POST",
        body: arg
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    updateApiKey: builder.mutation<ApiKeyResultType, UpdateApiKeyQueryArg>({
      query: (arg) => ({
        url: `/integration/apikey/${arg.id}`,
        method: "PUT",
        body: arg.body
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    deleteApiKey: builder.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    activateApiKey: builder.mutation<ApiKeyResultType, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}/activate`,
        method: "POST"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    deActivateApiKey: builder.mutation<ApiKeyResultType, { id: string }>({
      query: ({ id }) => ({
        url: `/integration/apikey/${id}/deactivate`,
        method: "POST"
      }),
      invalidatesTags: ["ApiKeys"]
    }),
    requestDeveloperAccessAndPartnerAPIKey: builder.mutation<
      requestDeveloperAccessAndPartnerAPIKeyQueryResult,
      RequestDeveloperAccessFormData
    >({
      query: (body) => ({
        url: `/auth/partner-api-access`,
        method: "POST",
        body
      }),
      invalidatesTags: ["RequesDeveloperAccessAndPartnerAPIKey"]
    }),
    getRequesDeveloperAccessAndPartnerAPIKeyStatus: builder.query<
      requestDeveloperAccessAndPartnerAPIKeyQueryStatusResult,
      unknown
    >({
      query: () => ({
        url: "/auth/partner-api-access",
        method: "GET"
      }),
      providesTags: ["RequesDeveloperAccessAndPartnerAPIKeyStatus"]
    }),
    deleteCustomCss: builder.mutation<
      unknown,
      { apiKeyId: string; themeName: string }
    >({
      query: ({ apiKeyId, themeName }) => ({
        url: `/integration/apikey/${apiKeyId}/embeddedPeakaConfig/customCss/${themeName}`,
        method: "DELETE"
      })
    }),
    uploadCustomCss: builder.mutation<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customCssContent: string;
        themes: string[];
      },
      { apiKeyId: string; cssFile: FormData; themeName: string }
    >({
      query: ({
        apiKeyId,
        themeName,
        cssFile
      }: {
        apiKeyId: string;
        themeName: string;
        cssFile: FormData;
      }) => {
        console.log({ cssFile });
        return {
          url: `/integration/apikey/${apiKeyId}/embeddedPeakaConfig/customCss/${themeName}/upload`,
          method: "POST",
          body: cssFile
        };
      }
    }),
    registerEmbeddedDomain: builder.mutation<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customCssContent: string;
      },
      { partnerDomain: string; apiKeyId: string }
    >({
      query: ({ apiKeyId, partnerDomain }) => ({
        url: `integration/apikey/${apiKeyId}/embeddedPeakaConfig/registerDomain`,
        method: "POST",
        body: { partnerDomain }
      })
    }),
    getEmbeddedConfig: builder.query<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customerName: string;
        customCssContent: string;
        enabled: boolean;
        themes: string[];
      },
      { apiKeyId: string }
    >({
      query: ({ apiKeyId }) => ({
        url: `/integration/apikey/${apiKeyId}/embeddedPeakaConfig`
      })
    }),
    setEmbeddedConfig: builder.mutation<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customerName: string;
        customCssContent: string;
        enabled: boolean;
        themes: string[];
      },
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customerName: string;
        enabled: boolean;
      }
    >({
      query: ({ partnerApiKeyId, partnerDomain, customerName, enabled }) => ({
        method: "POST",
        url: `/integration/apikey/${partnerApiKeyId}/embeddedPeakaConfig`,
        body: {
          partnerDomain,
          customerName,
          enabled
        }
      })
    }),
    updateEmbeddedConfig: builder.mutation<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customerName: string;
        customCssContent: string;
        enabled: boolean;
        themes: string[];
      },
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customerName: string;
        enabled: boolean;
      }
    >({
      query: ({ partnerApiKeyId, partnerDomain, customerName, enabled }) => ({
        method: "PUT",
        url: `/integration/apikey/${partnerApiKeyId}/embeddedPeakaConfig`,
        body: {
          partnerDomain,
          customerName,
          enabled
        }
      })
    }),
    deleteEmbeddedConfig: builder.mutation<
      {
        partnerApiKeyId: string;
        partnerDomain: string;
        customCssContent: string;
      },
      { apiKeyId: string }
    >({
      query: ({ apiKeyId }) => ({
        method: "DELETE",
        url: `/integration/apikey/${apiKeyId}/embeddedPeakaConfig`
      })
    }),
    getEmbeddedCss: builder.mutation<
      { cssFileContent: string },
      { theme: string }
    >({
      query: ({ theme }) => ({
        url: `/embedded-studio/css?theme=${theme}`,
        method: "GET"
      })
    }),
    getEmbeddedStudioConfig: builder.query<{ customerName: string }, void>({
      query: () => ({
        method: "GET",
        url: `/embedded-studio/config`
      })
    })
  })
});

export const {
  useGetApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useActivateApiKeyMutation,
  useDeActivateApiKeyMutation,
  useUpdateApiKeyMutation,
  useRequestDeveloperAccessAndPartnerAPIKeyMutation,
  useGetRequesDeveloperAccessAndPartnerAPIKeyStatusQuery,
  useUploadCustomCssMutation,
  useRegisterEmbeddedDomainMutation,
  useGetEmbeddedConfigQuery,
  useDeleteEmbeddedConfigMutation,
  useGetEmbeddedCssMutation,
  useDeleteCustomCssMutation,
  useSetEmbeddedConfigMutation,
  useGetEmbeddedStudioConfigQuery,
  useUpdateEmbeddedConfigMutation
} = apiKeyApi;
